<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In this experiment, you will be required to design a self-cooling can that results in a
        decrease in the temperature of the solution inside the can by
        <latex-number :number="tempChange" unit="^\circ\text{C.}" />
        Assuming you have a can that weighs
        <stemble-latex content="$15.00\,\text{g}$" />
        (with a heat capacity of
        <stemble-latex content="$\text{0.900 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}$" />
        ) and the solutions inside the can and the cooling jacket have masses of
        <stemble-latex content="$115.00\,\text{g}$" />
        and
        <stemble-latex content="$100.00\,\text{g,}$" />
        respectively (both with heat capacities of
        <stemble-latex content="$\text{4.184 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}$" />
        ), determine the mass of each of the following salts that would be required to achieve the
        desired temperature change.
      </p>

      <p class="mb-4">
        <b>NOTE:</b> Be sure to record the temperature change mentioned in the above paragraph that
        is unique to you. This temperature decrease is what you will be aiming to achieve in the
        simulated experiment.
      </p>

      <p class="mb-2">
        a) Mass of
        <chemical-latex content="KBr" />
        required:
      </p>

      <calculation-input
        v-model="inputs.mKBr"
        prepend-text="$\text{m}_\ce{KBr}:$"
        append-text="$\text{g}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Mass of
        <chemical-latex content="NH4Br" />
        required:
      </p>

      <calculation-input
        v-model="inputs.mNH4Br"
        prepend-text="$\text{m}_\ce{NH4Br}:$"
        append-text="$\text{g}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Mass of
        <chemical-latex content="KNO3" />
        required:
      </p>

      <calculation-input
        v-model="inputs.mKNO3"
        prepend-text="$\text{m}_\ce{KNO3}:$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemMcMPL4_Q9b',
  components: {
    ChemicalLatex,
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        mKBr: null,
        mNH4Br: null,
        mKNO3: null,
      },
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    tempChange() {
      return seededRandomBetween(this.seed, 5.5, 9.5, 1);
    },
  },
};
</script>
<style scoped></style>
